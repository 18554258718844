import { INVOICE } from "./constants";

export const addInvoiceClientsAction = (data): AuthAction => ({
  type: INVOICE.ADD_INVOICE_CLIENTS,
  payload: data,
});

export const updateInvoiceClientsAction = (data): AuthAction => ({
  type: INVOICE.UPDATE_INVOICE_CLIENTS,
  payload: data,
});

export const getInvoiceClientsAction = (data): AuthAction => ({
  type: INVOICE.GET_INVOICE_CLIENTS,
  payload: data,
});

export const addInvoiceItemAction = (data): AuthAction => ({
  type: INVOICE.ADD_INVOICE_ITEM,
  payload: data,
});

export const updateInvoiceItemAction = (data): AuthAction => ({
  type: INVOICE.UPDATE_INVOICE_ITEM,
  payload: data,
});

export const getInvoiceItemsAction = (data): AuthAction => ({
  type: INVOICE.GET_INVOICE_ITEMS,
  payload: data,
});

export const addInvoiceAction = (data): AuthAction => ({
  type: INVOICE.ADD_INVOICE,
  payload: data,
});

export const getInvoicesAction = (data): AuthAction => ({
  type: INVOICE.GET_INVOICE,
  payload: data,
});

export const getInvoiceByIdAction = (data): AuthAction => ({
  type: INVOICE.GET_INVOICE_BY_ID,
  payload: data,
});

export const updateInvoiceAction = (data): AuthAction => ({
  type: INVOICE.UPDATE_INVOICE,
  payload: data,
});

export const updateSettingsAction = (data): AuthAction => ({
  type: INVOICE.UPDATE_SETTINGS,
  payload: data,
});

export const addBankingAction = (data): AuthAction => ({
  type: INVOICE.ADD_BANKING,
  payload: data,
});

export const getBankingAction = (data): AuthAction => ({
  type: INVOICE.GET_BANKING,
  payload: data,
});

export const getDocumentsAction = (data): AuthAction => ({
  type: INVOICE.GET_DOCUMENTS,
  payload: data,
});

export const sendInvoiceReminder = (data): AuthAction => ({
  type: INVOICE.SEND_REMINDER,
  payload: data,
});

export const getItemByIdAction = (data): AuthAction => ({
  type: INVOICE.GET_ITEM_BY_ID,
  payload: data,
});