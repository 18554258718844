export const INVOICE = {
  ADD_INVOICE_CLIENTS: "ADD_INVOICE_CLIENTS",
  ADD_INVOICE_CLIENTS_LOADING: "ADD_INVOICE_CLIENTS_LOADING",
  ADD_INVOICE_CLIENTS_SUCCESS: "ADD_INVOICE_CLIENTS_SUCCESS",
  ADD_INVOICE_CLIENTS_ERROR: "ADD_INVOICE_CLIENTS_ERROR",
  ADD_INVOICE_CLIENTS_RESET: "ADD_INVOICE_CLIENTS_RESET",

  UPDATE_INVOICE_CLIENTS: "UPDATE_INVOICE_CLIENTS",
  UPDATE_INVOICE_CLIENTS_LOADING: "UPDATE_INVOICE_CLIENTS_LOADING",
  UPDATE_INVOICE_CLIENTS_SUCCESS: "UPDATE_INVOICE_CLIENTS_SUCCESS",
  UPDATE_INVOICE_CLIENTS_ERROR: "UPDATE_INVOICE_CLIENTS_ERROR",
  UPDATE_INVOICE_CLIENTS_RESET: "UPDATE_INVOICE_CLIENTS_RESET",

  GET_INVOICE_CLIENTS: "GET_INVOICE_CLIENTS",
  GET_INVOICE_CLIENTS_LOADING: "GET_INVOICE_CLIENTS_LOADING",
  GET_INVOICE_CLIENTS_SUCCESS: "GET_INVOICE_CLIENTS_SUCCESS",
  GET_INVOICE_CLIENTS_ERROR: "GET_INVOICE_CLIENTS_ERROR",
  GET_INVOICE_CLIENTS_RESET: "GET_INVOICE_CLIENTS_RESET",

  ADD_INVOICE_ITEM: "ADD_INVOICE_ITEM",
  ADD_INVOICE_ITEM_LOADING: "ADD_INVOICE_ITEM_LOADING",
  ADD_INVOICE_ITEM_SUCCESS: "ADD_INVOICE_ITEM_SUCCESS",
  ADD_INVOICE_ITEM_ERROR: "ADD_INVOICE_ITEM_ERROR",
  ADD_INVOICE_ITEM_RESET: "ADD_INVOICE_ITEM_RESET",

  UPDATE_INVOICE_ITEM: "UPDATE_INVOICE_ITEM",
  UPDATE_INVOICE_ITEM_LOADING: "UPDATE_INVOICE_ITEM_LOADING",
  UPDATE_INVOICE_ITEM_SUCCESS: "UPDATE_INVOICE_ITEM_SUCCESS",
  UPDATE_INVOICE_ITEM_ERROR: "UPDATE_INVOICE_ITEM_ERROR",
  UPDATE_INVOICE_ITEM_RESET: "UPDATE_INVOICE_ITEM_RESET",

  GET_INVOICE_ITEMS: "GET_INVOICE_ITEMS",
  GET_INVOICE_ITEMS_LOADING: "GET_INVOICE_ITEMS_LOADING",
  GET_INVOICE_ITEMS_SUCCESS: "GET_INVOICE_ITEMS_SUCCESS",
  GET_INVOICE_ITEMS_ERROR: "GET_INVOICE_ITEMS_ERROR",
  GET_INVOICE_ITEMS_RESET: "GET_INVOICE_ITEMS_RESET",

  ADD_INVOICE: "ADD_INVOICE",
  ADD_INVOICE_LOADING: "ADD_INVOICE_LOADING",
  ADD_INVOICE_SUCCESS: "ADD_INVOICE_SUCCESS",
  ADD_INVOICE_ERROR: "ADD_INVOICE_ERROR",
  ADD_INVOICE_RESET: "ADD_INVOICE_RESET",

  GET_INVOICE: "GET_INVOICE",
  GET_INVOICE_LOADING: "GET_INVOICE_LOADING",
  GET_INVOICE_SUCCESS: "GET_INVOICE_SUCCESS",
  GET_INVOICE_ERROR: "GET_INVOICE_ERROR",
  GET_INVOICE_RESET: "GET_INVOICE_RESET",

  GET_INVOICE_BY_ID: "GET_INVOICE_BY_ID",
  GET_INVOICE_BY_ID_LOADING: "GET_INVOICE_BY_ID_LOADING",
  GET_INVOICE_BY_ID_SUCCESS: "GET_INVOICE_BY_ID_SUCCESS",
  GET_INVOICE_BY_ID_ERROR: "GET_INVOICE_BY_ID_ERROR",
  GET_INVOICE_BY_ID_RESET: "GET_INVOICE_BY_ID_RESET",

  UPDATE_INVOICE: "UPDATE_INVOICE",
  UPDATE_INVOICE_LOADING: "UPDATE_INVOICE_LOADING",
  UPDATE_INVOICE_SUCCESS: "UPDATE_INVOICE_SUCCESS",
  UPDATE_INVOICE_ERROR: "UPDATE_INVOICE_ERROR",
  UPDATE_INVOICE_RESET: "UPDATE_INVOICE_RESET",

  UPDATE_SETTINGS: "UPDATE_SETTINGS",
  UPDATE_SETTINGS_LOADING: "UPDATE_SETTINGS_LOADING",
  UPDATE_SETTINGS_SUCCESS: "UPDATE_SETTINGS_SUCCESS",
  UPDATE_SETTINGS_ERROR: "UPDATE_SETTINGS_ERROR",
  UPDATE_SETTINGS_RESET: "UPDATE_SETTINGS_RESET",

  ADD_BANKING: "ADD_BANKING",
  ADD_BANKING_LOADING: "ADD_BANKING_LOADING",
  ADD_BANKING_SUCCESS: "ADD_BANKING_SUCCESS",
  ADD_BANKING_ERROR: "ADD_BANKING_ERROR",
  ADD_BANKING_RESET: "ADD_BANKING_RESET",

  GET_BANKING: "GET_BANKING",
  GET_BANKING_LOADING: "GET_BANKING_LOADING",
  GET_BANKING_SUCCESS: "GET_BANKING_SUCCESS",
  GET_BANKING_ERROR: "GET_BANKING_ERROR",
  GET_BANKING_RESET: "GET_BANKING_RESET",

  GET_DOCUMENTS: "GET_DOCUMENTS",
  GET_DOCUMENTS_LOADING: "GET_DOCUMENTS_LOADING",
  GET_DOCUMENTS_SUCCESS: "GET_DOCUMENTS_SUCCESS",
  GET_DOCUMENTS_ERROR: "GET_DOCUMENTS_ERROR",
  GET_DOCUMENTS_RESET: "GET_DOCUMENTS_RESET",

  SEND_REMINDER: "SEND_REMINDER",
  SEND_REMINDER_LOADING: "SEND_REMINDER_LOADING",
  SEND_REMINDER_SUCCESS: "SEND_REMINDER_SUCCESS",
  SEND_REMINDER_ERROR: "SEND_REMINDER_ERROR",
  SEND_REMINDER_RESET: "SEND_REMINDER_RESET",

  GET_ITEM_BY_ID: "GET_ITEM_BY_ID",
  GET_ITEM_BY_ID_LOADING: "GET_ITEM_BY_ID_LOADING",
  GET_ITEM_BY_ID_SUCCESS: "GET_ITEM_BY_ID_SUCCESS",
  GET_ITEM_BY_ID_ERROR: "GET_ITEM_BY_ID_ERROR",
  GET_ITEM_BY_ID_RESET: "GET_ITEM_BY_ID_RESET",

};
