import { APICore } from '../../../helpers/api/apiCore';
import * as URL from '../../../helpers/api/apiEndPoint';

const api = new APICore();

function getCompanyNameEndPoint(params?: string): any {
    let url = `${URL.GET_COMPANIES_BY_SEARCH}type=Client`;

    // Append search parameter even if it's empty
    url += `&search=${params ?? ''}`;

    return api.get(url);
}

function registerUserEndPoint(params: any): any {
    const { data } = params;
    return api.create(URL.REGISTER_USER, data);
}

function companyCheckVerifyEndPoint(params: any): any {
    const { data } = params;
    return api.create(URL.COMPANY_CHECK_VERIFY, data);
}

function companyCreateEndPoint(params: any): any {
    const { data } = params;
    return api.create(URL.COMPANY_CREATE, data);
}

export {
    getCompanyNameEndPoint,
    registerUserEndPoint,
    companyCheckVerifyEndPoint,
    companyCreateEndPoint
};
