import { BrowserRouter, useLocation } from "react-router-dom";
import { AllRoutes } from "./index";
import ActionButton from "../components/ActionButton/ActionButton";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Routes = () => {
  return (
    <BrowserRouter future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
      <AllRoutes />
    </BrowserRouter>
  );
};

export default Routes;
