import * as URL from "../../helpers/api/apiEndPoint";
import { APICore } from "../../helpers/api/apiCore";
const api = new APICore();

export function addInvoiceClientsApi(data): any {
  return api.create(URL.ADD_INVOICE_CLIENTS, data.payload);
}

export function updateInvoiceClientsApi(data): any {
  return api.update(URL.UPDATE_INVOICE_CLIENTS, data.payload);
}

export function getInvoiceClientsApi(data): any {
  return api.get(`${URL.GET_INVOICE_CLIENTS}?page=${data?.payload?.skip}&limit=${data?.payload?.limit}&search=${data?.payload?.search}`,);
}

export function addInvoiceItemApi(data): any {
  return api.create(URL.ADD_INVOICE_ITEM, data.payload);
}

export function updateInvoiceItemApi(data): any {
  return api.update(URL.UPDATE_INVOICE_ITEM, data.payload);
}

export function getInvoiceItemsApi(data): any {
  return api.get(`${URL.GET_INVOICE_ITEMS}?page=${data?.payload?.skip}&limit=${data?.payload?.limit}&search=${data?.payload?.search}`,);
}

export function addInvoiceApi(data): any {
  return api.create(URL.ADD_INVOICE, data.payload);
}

export function getInvoicesApi(data): any {
  return api.get(`${URL.GET_INVOICES}?page=${data?.payload?.skip}&limit=${data?.payload?.limit}&search=${data?.payload?.search}&type=${data?.payload?.type}&status=${data?.payload?.status || ''}&startDate=${data?.payload?.startDate}&endDate=${data?.payload?.endDate}`,);
}

export function updateInvoiceApi(data): any {
  return api.update(URL.UPDATE_INVOICE, data.payload);
}

export function getInvoiceByIdApi(data): any {
  return api.get(`${URL.GET_INVOICE_BY_ID}?_id=${data?.payload?.id}`);
}

export function updateSettingsApi(data): any {
  return api.update(URL.UPDATE_SETTINGS, data.payload);
}

export function addBankingDetailsApi(data): any {
  return api.create(URL.ADD_BANKING_DETAILS, data.payload);
}
export function getBankingApi(data): any {
  return api.get(`${URL.GET_BANKING}?page=${data?.payload?.skip}&limit=${data?.payload?.limit}&search=${data?.payload?.search}&iban=${data?.payload?.iban}`,);
}

export function getDocumentsApi(data): any {
  return api.get(`${URL.GET_DOCUMENTS}?page=${data?.payload?.skip}&limit=${data?.payload?.limit}&search=${data?.payload?.search}`,);
}

export function sendReminderApi(data): any {
  return api.update(URL.SEND_INVOICE_REMINDER, data.payload);
}

export function getItemByIdApi(data): any {
  return api.get(`${URL.GET_ITEM_BY_ID}?_id=${data?.payload?.id}`);
}