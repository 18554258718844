import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { INVOICE } from "./constants";
import {
  addBankingDetailsApi, addInvoiceApi, addInvoiceClientsApi, addInvoiceItemApi, getBankingApi,
  getDocumentsApi, getInvoiceByIdApi, getInvoiceClientsApi, getInvoiceItemsApi,
  getInvoicesApi, getItemByIdApi, sendReminderApi, updateInvoiceApi, updateInvoiceClientsApi, updateInvoiceItemApi, updateSettingsApi
} from "./api";
import ToastHandle from "../../helpers/ToastMessage";

function* addInvoiceClientsFunction({ payload }) {
  try {
    yield put({
      type: INVOICE.ADD_INVOICE_CLIENTS_LOADING,
      payload: {},
    });
    const response = yield call(addInvoiceClientsApi, { payload });
    if (response.data.status) {
      ToastHandle(response.data.message, 'success')
      yield put({
        type: INVOICE.ADD_INVOICE_CLIENTS_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: INVOICE.ADD_INVOICE_CLIENTS_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: INVOICE.ADD_INVOICE_CLIENTS_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    ToastHandle(error?.data?.message, 'danger')
    yield put({
      type: INVOICE.ADD_INVOICE_CLIENTS_ERROR,
      payload: error?.data,
    });
    yield put({
      type: INVOICE.ADD_INVOICE_CLIENTS_RESET,
      payload: {},
    });
  }
}

function* updateInvoiceClientsFunction({ payload }) {
  try {
    yield put({
      type: INVOICE.UPDATE_INVOICE_CLIENTS_LOADING,
      payload: {},
    });
    const response = yield call(updateInvoiceClientsApi, { payload });
    if (response.data.status) {
      yield put({
        type: INVOICE.UPDATE_INVOICE_CLIENTS_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: INVOICE.UPDATE_INVOICE_CLIENTS_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: INVOICE.UPDATE_INVOICE_CLIENTS_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    ToastHandle(error?.data?.message, 'danger')
    yield put({
      type: INVOICE.UPDATE_INVOICE_CLIENTS_ERROR,
      payload: error?.data,
    });
    yield put({
      type: INVOICE.UPDATE_INVOICE_CLIENTS_RESET,
      payload: {},
    });
  }
}

function* getInvoiceClientsFunction({ payload }) {
  try {
    yield put({
      type: INVOICE.GET_INVOICE_CLIENTS_LOADING,
      payload: {},
    });
    const response = yield call(getInvoiceClientsApi, { payload });
    if (response.data.status) {
      yield put({
        type: INVOICE.GET_INVOICE_CLIENTS_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: INVOICE.GET_INVOICE_CLIENTS_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: INVOICE.GET_INVOICE_CLIENTS_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {

    yield put({
      type: INVOICE.GET_INVOICE_CLIENTS_ERROR,
      payload: error?.data,
    });
    yield put({
      type: INVOICE.GET_INVOICE_CLIENTS_RESET,
      payload: {},
    });
  }
}

function* addInvoiceItemFunction({ payload }) {
  try {
    yield put({
      type: INVOICE.ADD_INVOICE_ITEM_LOADING,
      payload: {},
    });
    const response = yield call(addInvoiceItemApi, { payload });
    if (response.data.status) {
      ToastHandle(response.data.message, 'success')
      yield put({
        type: INVOICE.ADD_INVOICE_ITEM_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: INVOICE.ADD_INVOICE_ITEM_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: INVOICE.ADD_INVOICE_ITEM_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    ToastHandle(error?.data?.message, 'danger')
    yield put({
      type: INVOICE.ADD_INVOICE_ITEM_ERROR,
      payload: error?.data,
    });
    yield put({
      type: INVOICE.ADD_INVOICE_ITEM_RESET,
      payload: {},
    });
  }
}


function* updateInvoiceItemFunction({ payload }) {
  try {
    yield put({
      type: INVOICE.UPDATE_INVOICE_ITEM_LOADING,
      payload: {},
    });
    const response = yield call(updateInvoiceItemApi, { payload });
    if (response.data.status) {
      // Check the current URL before showing the success toast
      const currentUrl = window.location.pathname;
      if (currentUrl !== '/dashboard/newInvoice') {
        ToastHandle(response.data.message, 'success')
      }
      yield put({
        type: INVOICE.UPDATE_INVOICE_ITEM_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: INVOICE.UPDATE_INVOICE_ITEM_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: INVOICE.UPDATE_INVOICE_ITEM_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    ToastHandle(error?.data?.message, 'danger')
    yield put({
      type: INVOICE.UPDATE_INVOICE_ITEM_ERROR,
      payload: error?.data,
    });
    yield put({
      type: INVOICE.UPDATE_INVOICE_ITEM_RESET,
      payload: {},
    });
  }
}

function* getInvoiceItemsFunction({ payload }) {
  try {
    yield put({
      type: INVOICE.GET_INVOICE_ITEMS_LOADING,
      payload: {},
    });
    const response = yield call(getInvoiceItemsApi, { payload });
    if (response.data.status) {
      yield put({
        type: INVOICE.GET_INVOICE_ITEMS_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: INVOICE.GET_INVOICE_ITEMS_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: INVOICE.GET_INVOICE_ITEMS_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {

    yield put({
      type: INVOICE.GET_INVOICE_ITEMS_ERROR,
      payload: error?.data,
    });
    yield put({
      type: INVOICE.GET_INVOICE_ITEMS_RESET,
      payload: {},
    });
  }
}

function* addInvoiceFunction({ payload }) {
  try {
    yield put({
      type: INVOICE.ADD_INVOICE_LOADING,
      payload: {},
    });
    const response = yield call(addInvoiceApi, { payload });
    if (response.data.status) {
      ToastHandle(response.data.message, 'success')
      yield put({
        type: INVOICE.ADD_INVOICE_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: INVOICE.ADD_INVOICE_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: INVOICE.ADD_INVOICE_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    ToastHandle(error?.data?.message, 'danger')

    yield put({
      type: INVOICE.ADD_INVOICE_ERROR,
      payload: error?.data,
    });
    yield put({
      type: INVOICE.ADD_INVOICE_RESET,
      payload: {},
    });
  }
}

function* getInvoicesFunction({ payload }) {
  try {
    yield put({
      type: INVOICE.GET_INVOICE_LOADING,
      payload: {},
    });
    const response = yield call(getInvoicesApi, { payload });
    if (response.data.status) {
      yield put({
        type: INVOICE.GET_INVOICE_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: INVOICE.GET_INVOICE_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: INVOICE.GET_INVOICE_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: INVOICE.GET_INVOICE_ERROR,
      payload: error?.data,
    });
    yield put({
      type: INVOICE.GET_INVOICE_RESET,
      payload: {},
    });
  }
}

function* updateInvoiceFunction({ payload }) {
  let emailSubjectExists = false;
  payload.forEach((value, key) => {
    if (key === 'emailSubject') {
      emailSubjectExists = true;
    }
  });
  try {
    yield put({
      type: INVOICE.UPDATE_INVOICE_LOADING,
      payload: {},
    });
    const response = yield call(updateInvoiceApi, { payload });
    if (response.data.status) {
      if (emailSubjectExists) {
        console.log(response?.data, 'responseresponse')
        ToastHandle(`${response?.data?.response?.type} sent successfully!`, 'success')
      } else {
        ToastHandle(response.data.message, 'success')
      }
      yield put({
        type: INVOICE.UPDATE_INVOICE_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: INVOICE.UPDATE_INVOICE_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: INVOICE.UPDATE_INVOICE_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    ToastHandle(error?.data?.message, 'danger')
    yield put({
      type: INVOICE.UPDATE_INVOICE_ERROR,
      payload: error?.data,
    });
    yield put({
      type: INVOICE.UPDATE_INVOICE_RESET,
      payload: {},
    });
  }
}

function* getInvoiceByIdFunction({ payload }) {
  try {
    yield put({
      type: INVOICE.GET_INVOICE_BY_ID_LOADING,
      payload: {},
    });
    const response = yield call(getInvoiceByIdApi, { payload });
    if (response.data.status) {
      yield put({
        type: INVOICE.GET_INVOICE_BY_ID_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: INVOICE.GET_INVOICE_BY_ID_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: INVOICE.GET_INVOICE_BY_ID_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {

    yield put({
      type: INVOICE.GET_INVOICE_BY_ID_ERROR,
      payload: error?.data,
    });
    yield put({
      type: INVOICE.GET_INVOICE_BY_ID_RESET,
      payload: {},
    });
  }
}

function* updateSettingsFunction({ payload }) {
  try {
    yield put({
      type: INVOICE.UPDATE_SETTINGS_LOADING,
      payload: {},
    });
    const response = yield call(updateSettingsApi, { payload });
    if (response.data.status) {
      ToastHandle(response.data.message, 'success')
      yield put({
        type: INVOICE.UPDATE_SETTINGS_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: INVOICE.UPDATE_SETTINGS_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: INVOICE.UPDATE_SETTINGS_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    ToastHandle(error?.data?.message, 'danger')
    yield put({
      type: INVOICE.UPDATE_SETTINGS_ERROR,
      payload: error?.data,
    });
    yield put({
      type: INVOICE.UPDATE_SETTINGS_RESET,
      payload: {},
    });
  }
}

function* addBankingFunction({ payload }) {
  try {
    yield put({
      type: INVOICE.ADD_BANKING_LOADING,
      payload: {},
    });
    const response = yield call(addBankingDetailsApi, { payload });
    if (response.data.status) {
      ToastHandle(response.data.message, 'success')
      yield put({
        type: INVOICE.ADD_BANKING_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: INVOICE.ADD_BANKING_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: INVOICE.ADD_BANKING_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    ToastHandle(error?.data?.message, 'danger')

    yield put({
      type: INVOICE.ADD_BANKING_ERROR,
      payload: error?.data,
    });
    yield put({
      type: INVOICE.ADD_BANKING_RESET,
      payload: {},
    });
  }
}

function* getBankingFunction({ payload }) {
  try {
    yield put({
      type: INVOICE.GET_BANKING_LOADING,
      payload: {},
    });
    const response = yield call(getBankingApi, { payload });
    if (response.data.status) {
      yield put({
        type: INVOICE.GET_BANKING_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: INVOICE.GET_BANKING_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: INVOICE.GET_BANKING_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: INVOICE.GET_BANKING_ERROR,
      payload: error?.data,
    });
    yield put({
      type: INVOICE.GET_BANKING_RESET,
      payload: {},
    });
  }
}

function* getDocumentsFunction({ payload }) {
  try {
    yield put({
      type: INVOICE.GET_DOCUMENTS_LOADING,
      payload: {},
    });
    const response = yield call(getDocumentsApi, { payload });
    if (response.data.status) {
      yield put({
        type: INVOICE.GET_DOCUMENTS_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: INVOICE.GET_DOCUMENTS_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: INVOICE.GET_DOCUMENTS_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: INVOICE.GET_DOCUMENTS_ERROR,
      payload: error?.data,
    });
    yield put({
      type: INVOICE.GET_DOCUMENTS_RESET,
      payload: {},
    });
  }
}

function* sendReminderFunction({ payload }) {
  try {
    yield put({
      type: INVOICE.SEND_REMINDER_LOADING,
      payload: {},
    });
    const response = yield call(sendReminderApi, { payload });
    if (response.data.status) {
      ToastHandle(response.data.message, 'success')
      yield put({
        type: INVOICE.SEND_REMINDER_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: INVOICE.SEND_REMINDER_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: INVOICE.SEND_REMINDER_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    ToastHandle(error?.data?.message, 'danger')

    yield put({
      type: INVOICE.SEND_REMINDER_ERROR,
      payload: error?.data,
    });
    yield put({
      type: INVOICE.SEND_REMINDER_RESET,
      payload: {},
    });
  }
}

function* getItemByIdFunction({ payload }) {
  try {
    yield put({
      type: INVOICE.GET_ITEM_BY_ID_LOADING,
      payload: {},
    });
    const response = yield call(getItemByIdApi, { payload });
    if (response.data.status) {
      yield put({
        type: INVOICE.GET_ITEM_BY_ID_SUCCESS,
        payload: { ...response.data },
      });
      yield put({
        type: INVOICE.GET_ITEM_BY_ID_RESET,
        payload: {},
      });
    } else {
      yield put({
        type: INVOICE.GET_ITEM_BY_ID_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {

    yield put({
      type: INVOICE.GET_ITEM_BY_ID_ERROR,
      payload: error?.data,
    });
    yield put({
      type: INVOICE.GET_ITEM_BY_ID_RESET,
      payload: {},
    });
  }
}

export function* addInvoiceClientSaga(): any {
  yield takeEvery(INVOICE.ADD_INVOICE_CLIENTS, addInvoiceClientsFunction);
}

export function* updateInvoiceClientsSaga(): any {
  yield takeEvery(INVOICE.UPDATE_INVOICE_CLIENTS, updateInvoiceClientsFunction);
}

export function* getInvoiceClientsSaga(): any {
  yield takeEvery(INVOICE.GET_INVOICE_CLIENTS, getInvoiceClientsFunction);
}

export function* addInvoiceItemSaga(): any {
  yield takeEvery(INVOICE.ADD_INVOICE_ITEM, addInvoiceItemFunction);
}

export function* updateInvoiceItemSaga(): any {
  yield takeEvery(INVOICE.UPDATE_INVOICE_ITEM, updateInvoiceItemFunction);
}

export function* getInvoiceItemsSaga(): any {
  yield takeEvery(INVOICE.GET_INVOICE_ITEMS, getInvoiceItemsFunction);
}

export function* addInvoiceSaga(): any {
  yield takeEvery(INVOICE.ADD_INVOICE, addInvoiceFunction);
}

export function* getInvoicesSaga(): any {
  yield takeEvery(INVOICE.GET_INVOICE, getInvoicesFunction);
}

export function* updateInvoiceSaga(): any {
  yield takeEvery(INVOICE.UPDATE_INVOICE, updateInvoiceFunction);
}

export function* getInvoiceByIdSaga(): any {
  yield takeEvery(INVOICE.GET_INVOICE_BY_ID, getInvoiceByIdFunction);
}

export function* updateSettingsSaga(): any {
  yield takeEvery(INVOICE.UPDATE_SETTINGS, updateSettingsFunction);
}

export function* addBankingSaga(): any {
  yield takeEvery(INVOICE.ADD_BANKING, addBankingFunction);
}

export function* getBankingSaga(): any {
  yield takeEvery(INVOICE.GET_BANKING, getBankingFunction);
}

export function* getDocumentsSaga(): any {
  yield takeEvery(INVOICE.GET_DOCUMENTS, getDocumentsFunction);
}

export function* sendReminderSaga(): any {
  yield takeEvery(INVOICE.SEND_REMINDER, sendReminderFunction);
}

export function* getItemByIdSaga(): any {
  yield takeEvery(INVOICE.GET_ITEM_BY_ID, getItemByIdFunction);
}
function* invoicesSaga(): any {
  yield all([
    fork(addInvoiceClientSaga),
    fork(updateInvoiceClientsSaga),
    fork(getInvoiceClientsSaga),
    fork(addInvoiceItemSaga),
    fork(updateInvoiceItemSaga),
    fork(getInvoiceItemsSaga),
    fork(addInvoiceSaga),
    fork(updateInvoiceSaga),
    fork(getInvoicesSaga),
    fork(addBankingSaga),
    fork(getInvoiceByIdSaga), fork(updateSettingsSaga),
    fork(getBankingSaga), fork(getDocumentsSaga), fork(sendReminderSaga),
    fork(getItemByIdSaga)
  ]);
}

export default invoicesSaga;
