import { INVOICE } from "./constants";

const initial_state = {
  data: [],
  message: "",
  loading: false,
};
const ADD_INVOICE_CLIENTS_INITIAL_STATE = {
  data: [],
  loading: false
}

const UPDATE_INVOICE_CLIENTS_INITIAL_STATE = {
  data: [],
  loading: false
}

const GET_INVOICE_CLIENTS_INITIAL_STATE = {
  data: [],
  loading: false
}

const ADD_INVOICE_ITEM_INITIAL_STATE = {
  data: [],
  loading: false
}

const UPDATE_INVOICE_ITEM_INITIAL_STATE = {
  data: [],
  loading: false
}

const GET_INVOICE_ITEMS_INITIAL_STATE = {
  data: [],
  loading: false
}

const ADD_INVOICE_INITIAL_STATE = {
  data: [],
  loading: false
}

const UPDATE_INVOICE_INITIAL_STATE = {
  data: [],
  loading: false
}

const GET_INVOICE_INITIAL_STATE = {
  data: [],
  loading: false
}

const GET_INVOICE_BY_ID_INITIAL_STATE = {
  data: [],
  loading: false
}
const UPDATE_SETTINGS_INITIAL_STATE = {
  data: [],
  loading: false
}

const ADD_BANKING_INITIAL_STATE = {
  data: [],
  loading: false
}
const GET_BANKING_INITIAL_STATE = {
  data: [],
  loading: false
}

const GET_DOCUMENTS_INITIAL_STATE = {
  data: [],
  loading: false
}

const SEND_REMINDER_INITIAL_STATE = {
  data: [],
  loading: false
}

const GET_ITEM_BY_ID_INITIAL_STATE = {
  data: [],
  loading: false
}
export const addInvoiceClientsReducer = (state = ADD_INVOICE_CLIENTS_INITIAL_STATE, action) => {
  switch (action.type) {
    case INVOICE.ADD_INVOICE_CLIENTS_LOADING:
      return {
        data: state?.data,
        loading: true,
      };
    case INVOICE.ADD_INVOICE_CLIENTS_SUCCESS:
      return {
        data: action?.payload,
        loading: false,
      };
    case INVOICE.ADD_INVOICE_CLIENTS_RESET:
      return {
        data: initial_state.data,
        loading: false,
      };
    case INVOICE.ADD_INVOICE_CLIENTS_ERROR:
      return {
        data: action?.payload,
        loading: false,
        // message: action?.payload,
      };
    default:
      return { ...state };
  }
};

export const updateInvoiceClientsReducer = (state = UPDATE_INVOICE_CLIENTS_INITIAL_STATE, action) => {
  switch (action.type) {
    case INVOICE.UPDATE_INVOICE_CLIENTS_LOADING:
      return {
        data: state?.data,
        loading: true,
      };
    case INVOICE.UPDATE_INVOICE_CLIENTS_SUCCESS:
      return {
        data: action?.payload,
        loading: false,
      };
    case INVOICE.UPDATE_INVOICE_CLIENTS_RESET:
      return {
        data: initial_state.data,
        loading: false,
      };
    case INVOICE.UPDATE_INVOICE_CLIENTS_ERROR:
      return {
        data: action?.payload,
        loading: false,
        // message: action?.payload,
      };
    default:
      return { ...state };
  }
};

export const getInvoiceClientsReducer = (state = GET_INVOICE_CLIENTS_INITIAL_STATE, action) => {
  switch (action.type) {
    case INVOICE.GET_INVOICE_CLIENTS_LOADING:
      return {
        data: state.data,
        loading: true,
      };
    case INVOICE.GET_INVOICE_CLIENTS_SUCCESS:
      return {
        data: action.payload,
        loading: false,
      };
    case INVOICE.GET_INVOICE_CLIENTS_ERROR:
      return {
        data: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const getInvoiceItemsReducer = (state = GET_INVOICE_ITEMS_INITIAL_STATE, action) => {
  switch (action.type) {
    case INVOICE.GET_INVOICE_ITEMS_LOADING:
      return {
        data: state.data,
        loading: true,
      };
    case INVOICE.GET_INVOICE_ITEMS_SUCCESS:
      return {
        data: action.payload,
        loading: false,
      };
    case INVOICE.GET_INVOICE_ITEMS_ERROR:
      return {
        data: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const addInvoiceItemReducer = (state = ADD_INVOICE_ITEM_INITIAL_STATE, action) => {
  switch (action.type) {
    case INVOICE.ADD_INVOICE_ITEM_LOADING:
      return {
        data: state?.data,
        loading: true,
      };
    case INVOICE.ADD_INVOICE_ITEM_SUCCESS:
      return {
        data: action?.payload,
        loading: false,
      };
    case INVOICE.ADD_INVOICE_ITEM_RESET:
      return {
        data: initial_state.data,
        loading: false,
      };
    case INVOICE.ADD_INVOICE_ITEM_ERROR:
      return {
        data: action?.payload,
        loading: false,
      };
    default:
      return { ...state };
  }
};

export const updateInvoiceItemReducer = (state = UPDATE_INVOICE_ITEM_INITIAL_STATE, action) => {
  switch (action.type) {
    case INVOICE.UPDATE_INVOICE_ITEM_LOADING:
      return {
        data: state?.data,
        loading: true,
      };
    case INVOICE.UPDATE_INVOICE_ITEM_SUCCESS:
      return {
        data: action?.payload,
        loading: false,
      };
    case INVOICE.UPDATE_INVOICE_ITEM_RESET:
      return {
        data: initial_state.data,
        loading: false,
      };
    case INVOICE.UPDATE_INVOICE_ITEM_ERROR:
      return {
        data: action?.payload,
        loading: false,
      };
    default:
      return { ...state };
  }
};

export const addInvoiceReducer = (state = ADD_INVOICE_INITIAL_STATE, action) => {
  switch (action.type) {
    case INVOICE.ADD_INVOICE_LOADING:
      return {
        data: state?.data,
        loading: true,
      };
    case INVOICE.ADD_INVOICE_SUCCESS:
      return {
        data: action?.payload,
        loading: false,
      };
    case INVOICE.ADD_INVOICE_RESET:
      return {
        data: initial_state.data,
        loading: false,
      };
    case INVOICE.ADD_INVOICE_ERROR:
      return {
        data: action?.payload,
        loading: false,
      };
    default:
      return { ...state };
  }
};

export const getInvoicesReducer = (state = GET_INVOICE_INITIAL_STATE, action) => {
  switch (action.type) {
    case INVOICE.GET_INVOICE_LOADING:
      return {
        data: state.data,
        loading: true,
      };
    case INVOICE.GET_INVOICE_SUCCESS:
      return {
        data: action.payload,
        loading: false,
      };
    case INVOICE.GET_INVOICE_ERROR:
      return {
        data: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const getInvoiceByIdReducer = (state = GET_INVOICE_BY_ID_INITIAL_STATE, action) => {
  switch (action.type) {
    case INVOICE.GET_INVOICE_BY_ID_LOADING:
      return {
        data: state.data,
        loading: true,
      };
    case INVOICE.GET_INVOICE_BY_ID_SUCCESS:
      return {
        data: action.payload,
        loading: false,
      };
    case INVOICE.GET_INVOICE_BY_ID_ERROR:
      return {
        data: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const updateInvoiceReducer = (state = UPDATE_INVOICE_INITIAL_STATE, action) => {
  switch (action.type) {
    case INVOICE.UPDATE_INVOICE_LOADING:
      return {
        data: state?.data,
        loading: true,
      };
    case INVOICE.UPDATE_INVOICE_SUCCESS:
      return {
        data: action?.payload,
        loading: false,
      };
    case INVOICE.UPDATE_INVOICE_RESET:
      return {
        data: initial_state.data,
        loading: false,
      };
    case INVOICE.UPDATE_INVOICE_ERROR:
      return {
        data: action?.payload,
        loading: false,
      };
    default:
      return { ...state };
  }
};

export const updateSettingsReducer = (state = UPDATE_SETTINGS_INITIAL_STATE, action) => {
  switch (action.type) {
    case INVOICE.UPDATE_SETTINGS_LOADING:
      return {
        data: state?.data,
        loading: true,
      };
    case INVOICE.UPDATE_SETTINGS_SUCCESS:
      return {
        data: action?.payload,
        loading: false,
      };
    case INVOICE.UPDATE_SETTINGS_RESET:
      return {
        data: initial_state.data,
        loading: false,
      };
    case INVOICE.UPDATE_SETTINGS_ERROR:
      return {
        data: action?.payload,
        loading: false,
      };
    default:
      return { ...state };
  }
};

export const addBankingReducer = (state = ADD_BANKING_INITIAL_STATE, action) => {
  switch (action.type) {
    case INVOICE.ADD_BANKING_LOADING:
      return {
        data: state?.data,
        loading: true,
      };
    case INVOICE.ADD_BANKING_SUCCESS:
      return {
        data: action?.payload,
        loading: false,
      };
    case INVOICE.ADD_BANKING_RESET:
      return {
        data: initial_state.data,
        loading: false,
      };
    case INVOICE.ADD_BANKING_ERROR:
      return {
        data: action?.payload,
        loading: false,
      };
    default:
      return { ...state };
  }
};

export const getBankingReducer = (state = GET_BANKING_INITIAL_STATE, action) => {
  switch (action.type) {
    case INVOICE.GET_BANKING_LOADING:
      return {
        data: state.data,
        loading: true,
      };
    case INVOICE.GET_BANKING_SUCCESS:
      return {
        data: action.payload,
        loading: false,
      };
    case INVOICE.GET_BANKING_ERROR:
      return {
        data: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const getDocumentsReducer = (state = GET_DOCUMENTS_INITIAL_STATE, action) => {
  switch (action.type) {
    case INVOICE.GET_DOCUMENTS_LOADING:
      return {
        data: state.data,
        loading: true,
      };
    case INVOICE.GET_DOCUMENTS_SUCCESS:
      return {
        data: action.payload,
        loading: false,
      };
    case INVOICE.GET_DOCUMENTS_ERROR:
      return {
        data: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
export const sendReminderReducer = (state = SEND_REMINDER_INITIAL_STATE, action) => {
  switch (action.type) {
    case INVOICE.SEND_REMINDER_LOADING:
      return {
        data: state?.data,
        loading: true,
      };
    case INVOICE.SEND_REMINDER_SUCCESS:
      return {
        data: action?.payload,
        loading: false,
      };
    case INVOICE.SEND_REMINDER_RESET:
      return {
        data: initial_state.data,
        loading: false,
      };
    case INVOICE.SEND_REMINDER_ERROR:
      return {
        data: action?.payload,
        loading: false,
      };
    default:
      return { ...state };
  }
};

export const getItemByIdReducer = (state = GET_ITEM_BY_ID_INITIAL_STATE, action) => {
  switch (action.type) {
    case INVOICE.GET_ITEM_BY_ID_LOADING:
      return {
        data: state.data,
        loading: true,
      };
    case INVOICE.GET_ITEM_BY_ID_SUCCESS:
      return {
        data: action.payload,
        loading: false,
      };
    case INVOICE.GET_ITEM_BY_ID_ERROR:
      return {
        data: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
